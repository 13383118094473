/** @jsx jsx */
import { StaticQuery, graphql, Link } from 'gatsby';
import { Box, Text, jsx } from 'theme-ui';
import { RichText } from 'prismic-reactjs';
import SocialLinks from '../../../social-links/social-links';
import styles from './footer.style';
import Container from '../../../container/container';

const menuStaticQuery = graphql`
	query {
		prismic {
			allChat2ordercommons {
				edges {
					node {
						copyright_text
						social_links {
							social_link {
								... on PRISMIC__ExternalLink {
									url
								}
							}
							social_type
						}
					}
				}
			}
		}
	}
`;

const getSocialData = (links: any) => {
	const data: any = [];
	links.forEach((link: any) => {
		data.push({ type: link?.social_type, link: link?.social_link?.url });
	});
	return data;
};

const Footer : React.FC<{ fluid?: boolean }> = (fluid?: boolean) => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${menuStaticQuery}`}
		render={(data: any) => {
			const footerData = data?.prismic?.allChat2ordercommons?.edges[0]?.node;
			const socialData = getSocialData(footerData?.social_links || []);
			return (
				<Box as="footer" sx={styles.footer}>
					<Container fluid={true}>
						<Text className="copyright" sx={styles.copyright}>
							{RichText.render(footerData.copyright_text)}
						</Text>
						<Link
							activeClassName="active"
							to={`/about`}
							sx={styles.about}
						>
							About us
						</Link>	
						<Link
							activeClassName="active"
							to={`/support`}
							sx={styles.about}
						>
							Support
						</Link>													
						<Link
							activeClassName="active"
							to={`/privacy`}
							sx={styles.about}
						>
							Privacy
						</Link>			
						<Link
							activeClassName="active"
							to={`/terms`}
							sx={styles.about}
						>
							Terms & Conditions 
						</Link>			  						
						<SocialLinks items={socialData} />
					</Container>
				</Box>
			);
		}}
	/>
);

export default Footer;
