/** @jsx jsx */
import React, {
	useRef,
	useState,
	useEffect,
	useContext,
	useCallback,
} from "react";
import Img from "gatsby-image";
import { Box, Flex, Button, Heading, Text, jsx, Image } from "theme-ui";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
/*import { CartContext } from "../../provider/cart-provider";
import { LocalCartContext } from "../../provider/local-cart-provider";
import useOnClickOutside from "../../hooks/useOutsideClick";*/
import styles from "./product-card.style";
import amazonBuy from '../../images/buy5.gif';

type PropsType = {
	price: any;
	title: string;
	thumbnail: any;
	url: string;
};

const ProductCard: React.FC<PropsType> = (product) => {
	const {
		title,
		price,
		thumbnail,
		url,
	} = product;
	/*const {
		store: { client },
	} = useContext(CartContext);*/

	//const { products, add, update } = useContext(LocalCartContext);
	//const counterRef = useRef(null!);
	//const [showCounter, setShowCounter] = useState(false);
	//useOnClickOutside(counterRef, () => setShowCounter(false));

	//const [variant, setVariant] = useState({ ...initialVariant });
	//const productVariant = variant;
	/*const productIndex = products.findIndex(
		(item) => item.variantId === productVariant.shopifyId
	);*/

	//const cartProduct = productIndex > -1 ? products[productIndex] : false;
	//const cartProductQuantity = cartProduct ? cartProduct.quantity : 0;
	//const [quantity, setQuantity] = useState(0);
	//const [available, setAvailable] = useState(productVariant.availableForSale);
	/*const checkAvailability = useCallback(
		(productId) => {
			client.product.fetch(productId).then((fetchedProduct) => {
				// this checks the currently selected variant for availability
				const result = fetchedProduct.variants.filter(
					(variant) => variant.id === productVariant.shopifyId
				);
				if (result && result.length > 0) {
					setAvailable(result[0].available);
				}
			});
		},
		[client.product, productVariant.shopifyId, variants]
	);*/

	/*useEffect(() => {
		setQuantity(cartProductQuantity);
	}, [cartProductQuantity]);*/

	/*useEffect(() => {
		checkAvailability(product.shopifyId);
	}, [productVariant, checkAvailability, product.shopifyId]);*/

	/*const handleQuantityChange = (quantity: number) => {
		update(productVariant.shopifyId, quantity);
		if (quantity < 1) {
			setShowCounter(false);
		}
	};*/

	/*const handleAddToCart = () => {
		if (!available) {
			return false;
		}
		setShowCounter(true);
		if (quantity < 1) {
			const item = {
				title,
				thumbnail,
				quantity: 1,
				price: productVariant.priceV2.amount,
				currency: productVariant.priceV2.currencyCode,
				variantId: productVariant.shopifyId,
			};
			add(item);
		}
	};*/

	const addClass = ["productCard"];
	/*if (quantity > 0 && showCounter) {
		addClass.push("active");
	}
	if (!available) {
		addClass.push("disabled");
	}*/

	return (
		<Box
			sx={styles.wrapper}
			className={addClass.join(" ")}
			onClick={() => typeof window !== `undefined` && window.open(url, '_blank') }
		>
			<Box className="image" sx={styles.imageWrapper}>
				{thumbnail && <Image src={thumbnail} />}
			</Box>

			<Box className="content">
				<Flex sx={styles.meta}>
					<Text as="span" sx={styles.price}>
						{price}
					</Text>
				</Flex>
				<Heading as="h4" sx={styles.title}>
					{title}
				</Heading>

				  <Image src={amazonBuy} />

			</Box>
		</Box>
	);
};

export default ProductCard;
