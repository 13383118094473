/** @jsx jsx */
import React, { useContext } from 'react';
import { Box, Flex, jsx } from 'theme-ui';
import { SearchContext } from '../../../provider/search-provider';
import useFreezeBodyScroll from '../../../hooks/useFreezeBodyScroll';
import Sidebar from './sidebar/sidebar';
import Header from './header/header';
import Footer from './footer/footer';
import styles from './secondary.style';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import '../../../react-whatsapp-widget/index.css';
import ChatIcon from '../../chatIcon';

const SecondaryLayout: React.FunctionComponent = ({ children }) => {
	const { isSearched } = useContext<any>(SearchContext);
	const welcometext1 = `Hello! 👋🏼 What can we do for you? Type below or visit`
	const welcometext2 = `for more sellers and products!`
	const replyTimeText = 'Typically replies in few mins'	
	useFreezeBodyScroll(isSearched);

	return (
		<Flex as="main" sx={styles.main}>
			<Box as="aside" sx={styles.sidebar}>
				<Sidebar />
			</Box>
			<Box sx={styles.wrapper}>
				<Header />
				<Box sx={styles.content}>{children}</Box>
				<Footer />
				<WhatsAppWidget replyTimeText={replyTimeText} sendButtonText="Whatsapp" message1={welcometext1} url="https://shops.chat2order.in" message2={welcometext2} companyName="Chat2Order" CompanyIcon={ChatIcon} phoneNumber="+918777675366" />									
			</Box>
		</Flex>
	);
};

export default SecondaryLayout;
