/** @jsx jsx */
import { useContext, useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { Box, Text, Image, jsx } from 'theme-ui';
import ProductGrid from '../product-grid/product-grid';
import AmazonGrid from '../amazon-grid/amazon-grid';
import { SearchContext } from '../../provider/search-provider';
import notFoundSVG from '../../images/no-result-found.svg';
import styles from './search.style';
import { Amplify, API } from 'aws-amplify';

Amplify.configure({
  // OPTIONAL - if your API requires authentication 
  Auth: {
    identityPoolId: 'ap-south-1:dfea2fb1-c98b-4fae-91cb-56478cddae1f', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-south-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'ap-south-1_sqESkxDgv', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '7g0n8d0f24kaiqmharucoivuv0', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  },
  API: {
    endpoints: [
      {
        name: "chat2order-amazon",
        endpoint: "https://bxben0d8n3.execute-api.ap-south-1.amazonaws.com"
      }
    ]
  }
});

type PropsType = {
	allShopifyProduct: any;
};

const fuseOptions = {
	isCaseSensitive: false,
	findAllMatches: false,
	includeMatches: false,
	includeScore: false,
	useExtendedSearch: false,
	minMatchCharLength: 2,
	shouldSort: true,
	threshold: 0.3, // lower threshold value means more accurate results
	location: 0,
	distance: 100,
	keys: ['node.title', 'node.tags'],
};

const Search: React.FC<PropsType> = (props) => {
	const { allShopifyProduct } = props;
	const products = allShopifyProduct?.edges || [];
	const [value, setValue] = useState('');
	const [searchData, setSearchData] = useState([]);
	const [noResult, setNoResult] = useState(false);
	const [amazonData, setAmazonData] = useState([]);
	
	const { setIsSearched } = useContext(SearchContext);
	const fuse = new Fuse(products, fuseOptions);

	useEffect(() => {
		if (value.length > 0) {
			setIsSearched(true);
		}
		return () => setIsSearched(false);
	}, [value]);

	useEffect(() => {
		const handler = setTimeout(() => {
			let searchData: any = [];
			if (value.length) {
				const fuseData = fuse.search(value);
				if (fuseData && fuseData.length) {
					fuseData.forEach((data) => {
						searchData.push(data.item);
					});
				}
			}
			setSearchData(searchData);
		}, 500);
		return () => clearTimeout(handler);
	}, [value]);

	useEffect(() => {
		const handler = setTimeout(() => {
			if (value.length && !searchData.length) {
				function getData() {
				  const apiName = 'chat2order-amazon';
				  const path = '/default/chat2order-shops/amazon';
				  const myInit = {
					headers: {} // OPTIONAL
				  };

				  return API.get(apiName, path,  {
							  queryStringParameters: {
								searchText: value
							  }
							});
				}	
				(async function() {
				  const response = await getData();
				  setAmazonData(response)
				})();							
				setNoResult(true);
			} else {
				setNoResult(false);
			}
		}, 1000);
		return () => {
			clearTimeout(handler);
		};
	}, [value, searchData]);

	const handleClear = () => {
		setValue('');
		setNoResult(false);
	};

	return (
		<Box className="picksySearch" sx={styles.wrapper}>
			<Box as="label" className="search" sx={styles.fieldWrapper}>
				<svg className="search-icon" width="16" height="16" viewBox="0 0 16 16">
					<path
						d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
						transform="translate(-4 -1.525)"
						fillRule="evenodd"
					/>
				</svg>

				<input
					type="text"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="What Are You Looking For?"
					autoComplete="off"
				/>
				{value && (
					<svg
						className="close-icon"
						onClick={() => setValue('')}
						width="14"
						height="14"
						viewBox="0 0 14 14"
					>
						<path
							d="M6.115,4.792A.936.936,0,0,0,4.792,6.115l5.4,5.4-5.4,5.4a.936.936,0,0,0,1.323,1.323l5.4-5.4,5.4,5.4a.936.936,0,0,0,1.323-1.323l-5.4-5.4,5.4-5.4a.936.936,0,1,0-1.323-1.323l-5.4,5.4Z"
							transform="translate(-4.518 -4.518)"
						/>
					</svg>
				)}
			</Box>

			{value && searchData.length ? (
				<Box className="searchResult" sx={styles.searchResult}>
					<ProductGrid
						gridTitle="Search Result"
						products={searchData}
						close={handleClear}
					/>
				</Box>
			) : null}
			{value && noResult && (
				<Box className="searchResult" sx={styles.searchResult}>
					<Box sx={styles.notFoundCard}>
						<Text as="h2">No Items Found on chat2Order :(</Text>
						<Text as="p">Sorry mate ... no items found inside your search</Text>
					</Box>
					<AmazonGrid
						gridTitle="Search Results from Amazon"
						products={amazonData}
						close={handleClear}
					/>					
				</Box>
			)}
		</Box>
	);
};

/*const amazonData = {
 "SearchResult": {
  "Items": [
   {
    "ASIN": "B074FT8BKJ",
    "DetailPageURL": "https://www.amazon.in/dp/B074FT8BKJ?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/51Jnt8tmFuL._SL160_.jpg",
       "Width": 100
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "Manasae Love Please ( மனசே லவ் பிளீஸ் ): 5 Romantic Poetic Novels (Kavithai) (Tamil Edition)",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UbgZNdxlefJiBlc2MkPEV0WfM84UFcrIOi9goPNNE9p6XUV9uBBhaRbUc0JHqTN%2FgvTTsi6nOlfRFLhhy1960rXbd0TcoOIHCUj9dTowvwuGaPLC%2BiYvmlG8nTNsZL3U83558jF2lN%2BG%2FaN3IEoTRyJwiccoXiEV9TTlL2faeV3%2BcDyBoxL77W0",
       "Price": {
        "Amount": 64,
        "Currency": "INR",
        "DisplayAmount": "₹64.00"
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "0060898046",
    "DetailPageURL": "https://www.amazon.in/dp/0060898046?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/41ksRLY+5kL._SL160_.jpg",
       "Width": 104
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "Polly: A Novel (P.S.)",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7Ubi6HQBTO%2BBJ8lTO9iIT0DvHZgI2bYhVt2DWI1bAE1L1DIOaxgYY3SZYJ95EhbD6o%2FAbc%2FsY6fgLbjVQizps1cCvNs6vIGJZbvnWvIcW%2FvXNaWIwQI0dNyBuBJ3kv6HPWVqxjM0FtWXcbK%2FAkoE43pygekOa5duNEypDauqhFqJAQnKN3fokaFQ",
       "Price": {
        "Amount": 1110,
        "Currency": "INR",
        "DisplayAmount": "₹1,110.00",
        "Savings": {
         "Amount": 207,
         "Currency": "INR",
         "DisplayAmount": "₹207.00 (16%)",
         "Percentage": 16
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "0143108867",
    "DetailPageURL": "https://www.amazon.in/dp/0143108867?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/41Njrnmfc1L._SL160_.jpg",
       "Width": 104
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "After You: A Novel: 2 (Me Before You Trilogy)",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UbjsjgSv%2BGp3hjdRm6TC4vvTdg%2B51J3DmlcdUW%2F220idZReam1cPvgGVDb3TATADVakCbY4KVYOpyXyhSZXT%2BFbIfziTcE%2FXC%2Fb4FmxyO7q2JtanMkNKYYW0%2Bn%2BTWEEmz3z%2BI6Px%2FTffNn8Qkm8m2mmDSw5rRCtCG0O%2BwLIVnLJ9oaKb4RwVUG2",
       "Price": {
        "Amount": 304.19,
        "Currency": "INR",
        "DisplayAmount": "₹304.19"
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "0143130153",
    "DetailPageURL": "https://www.amazon.in/dp/0143130153?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/51z4z79P27L._SL160_.jpg",
       "Width": 89
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "Me Before You: A Novel (Movie Tie-In)",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UbjsjgSv%2BGp3ljSkAZFu2w2spZyYTiZ4tW8QACU678dqixPHMj4shG8kkbbm4dlNUw4DY8yuBnOR58Tpttao5Yr9Frco724vgVvGx3yxSq%2B%2F2dkJEUFi6a3VobAjOmiFD1PEWdeq7LF9%2B9nR8ByzePwzXsZ0sDCTvQgbtFAq4c8aQ2bB95GhoOB",
       "Price": {
        "Amount": 1002,
        "Currency": "INR",
        "DisplayAmount": "₹1,002.00"
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "8172234988",
    "DetailPageURL": "https://www.amazon.in/dp/8172234988?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/41e+TR4xPDL._SL160_.jpg",
       "Width": 105
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "The Alchemist",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaIjYOV9%2BQ7jbdwVmHd3UHI6drqvtRT90URgg%2BZ0FUEkzlp2E8Ki14IN3a6suOl9VTucQQnfWTte%2F1xlPNTfokS1AeY5oyW2NHZ0HXKOmow6RGoREqvPFtWPCWIgimCQOrOGaNpz5AQuSm",
       "Price": {
        "Amount": 217,
        "Currency": "INR",
        "DisplayAmount": "₹217.00",
        "Savings": {
         "Amount": 133,
         "Currency": "INR",
         "DisplayAmount": "₹133.00 (38%)",
         "Percentage": 38
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "8173711461",
    "DetailPageURL": "https://www.amazon.in/dp/8173711461?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/51wbVQTpTgL._SL160_.jpg",
       "Width": 109
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "WINGS OF FIRE: AUTOBIOGRAPHY OF ABDUL KALAM",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaIpI4Zw1XdHzh9cXNqtLxAdmGSwFXHkSdGGP7RbzRAECC3yWIo%2FMYnbsq2GFCPKeZyt0ZB10JxRxGsxpo0SLEh6D7Y202g88sPMuaCb92fZsuF3ZXvZI0M8ZMSKtVYib8TWDTSOS0g%2FsCzRe4Apd%2Fy51H8qDmXAbK63i4YTrDw45x",
       "Price": {
        "Amount": 130,
        "Currency": "INR",
        "DisplayAmount": "₹130.00",
        "Savings": {
         "Amount": 255,
         "Currency": "INR",
         "DisplayAmount": "₹255.00 (66%)",
         "Percentage": 66
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "817992162X",
    "DetailPageURL": "https://www.amazon.in/dp/817992162X?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/410BqHSamiL._SL160_.jpg",
       "Width": 103
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "The Monk Who Sold His Ferrari",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaIs%2BwqewRgmh2o%2BOug09QlPiDM4IYSJoWaIKNRRO0qiu0qK%2B9JN5NZ%2FqCJZSOE5f%2BwcXkF6CDPWBTQeTU%2FVAwXryyXjP4nF5OTJPIS1Ag7AJcleqFc2MC334H1IT%2Fch78A6%2BPWgsFw8Er",
       "Price": {
        "Amount": 219,
        "Currency": "INR",
        "DisplayAmount": "₹219.00",
        "Savings": {
         "Amount": 80,
         "Currency": "INR",
         "DisplayAmount": "₹80.00 (27%)",
         "Percentage": 27
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "8179925919",
    "DetailPageURL": "https://www.amazon.in/dp/8179925919?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/51oHUvYzbsL._SL160_.jpg",
       "Width": 105
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "The Theory Of Everything",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaItnIgHbalM804yhkxmcokeH1Wpfr5Rm48D9MHNyaIO0bBRK9sP51OlszjRGeLBYV%2FCi%2FvvKJn1Yr4FQJ%2F90l2qGumHupwl4WGpunPM91mZrfDZ8Ik4xhSwuU5XuL8Lc2lerZlqM1f4L%2Bwx%2B%2BLbuNCRskVn8V9SyFhRaUvb9JEntY",
       "Price": {
        "Amount": 170,
        "Currency": "INR",
        "DisplayAmount": "₹170.00",
        "Savings": {
         "Amount": 55,
         "Currency": "INR",
         "DisplayAmount": "₹55.00 (24%)",
         "Percentage": 24
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "8179922324",
    "DetailPageURL": "https://www.amazon.in/dp/8179922324?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/21Hh+QOJKfL._SL160_.jpg",
       "Width": 116
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "Who Will Cry When You Die?",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaIqFqYg37Xbndk5wE%2BpHEp5DmU6eUHn9Rt%2FrKXMKDvNcUhfTKGaq74tkb%2BsYLBjUQg6L4fdyRd2cA2JTEnCDTMp26ur1WRw7PE5%2B2s6SrYsfOOgAtiGLD2WIKXOYxKRiwwBN92eNM1bsP",
       "Price": {
        "Amount": 176,
        "Currency": "INR",
        "DisplayAmount": "₹176.00",
        "Savings": {
         "Amount": 74,
         "Currency": "INR",
         "DisplayAmount": "₹74.00 (30%)",
         "Percentage": 30
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   },
   {
    "ASIN": "8171673406",
    "DetailPageURL": "https://www.amazon.in/dp/8171673406?tag=ncom02-21&linkCode=osi&th=1&psc=1",
    "Images": {
     "Primary": {
      "Medium": {
       "Height": 160,
       "URL": "https://m.media-amazon.com/images/I/51TGU8igF-L._SL160_.jpg",
       "Width": 99
      }
     }
    },
    "ItemInfo": {
     "Title": {
      "DisplayValue": "THE BLUE UMBRELLA",
      "Label": "Title",
      "Locale": "en_IN"
     }
    },
    "Offers": {
     "Listings": [
      {
       "Id": "EeMA6jrY7UYsXAnaRABaIi2WRYPaUsq%2FFaHPXCM%2FragtmsSXMk8csQ3ALg3O2B%2B6uftRSwV1IEhKsUzUB2sILZmW5CaOPNUtGQYPUF1Lsl7CNx9GfLINwNOmUO1aWG2d%2BQYCDCCt4GoWogjDD6FCKVEl9htRKTGp",
       "Price": {
        "Amount": 115,
        "Currency": "INR",
        "DisplayAmount": "₹115.00",
        "Savings": {
         "Amount": 35,
         "Currency": "INR",
         "DisplayAmount": "₹35.00 (23%)",
         "Percentage": 23
        }
       },
       "ViolatesMAP": false
      }
     ]
    }
   }
  ],
  "SearchURL": "https://www.amazon.in/s?k=benagali+novel&rh=p_n_availability%3A-1&tag=ncom02-21&linkCode=osi",
  "TotalResultCount": 31
 }
};*/

export default Search;
